<template>
  <div class="user-evaluation-component app-container">
    <div class="filter-line">
      <span>
        <el-button @click="getListData" icon="el-icon-refresh-right">{{
          this.$t("commons.refresh")
        }}</el-button>
        <el-button type="primary" @click="exportHandler">{{
          this.$t("commons.export")
        }}</el-button>
      </span>
      <div
        class="header-filter-button"
        @click="dataset.filterLine.show = !dataset.filterLine.show"
        :class="
          dataset.filterLine.show ? 'filter-button-open' : 'filter-button-close'
        "
      >
        <i class="iconfont icon-loudoutu"></i>
        筛选
      </div>
    </div>
    <div>
      <finalTable
        ref="finalTableRef"
        :datas="dataset"
        @tableEventHandler="tableEventHandler"
      />
    </div>
    <!-- 新增&编辑弹框 -->
    <el-dialog
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      width="728px"
      :destroy-on-close="true"
      :close-on-click-modal="false"
    >
      <template slot="title">
        <span>用户评价详情</span>
      </template>
      <EvalutionDetail :dataset="viewDatas" @close="handleClose" />
    </el-dialog>
  </div>
</template>

<script>
import finalTable from "@/components/FinalTable";
import EvalutionDetail from "./evalutionDetail.vue";
import { findLookupItem } from "@/api/ruge/lookup/lookup";
import {
  getEvalutionList,
  evalutionListExport,
} from "@/api/ruge/gsPark/customerService/userEvalution";
export default {
  name: "userEvaluationComponent",
  components: {
    finalTable,
    EvalutionDetail,
  },
  data() {
    return {
      viewDatas: {},
      dialogVisible: false,
      listLoading: false,
      requestParams: {
        current: 1,
        rowCount: 10,
        startTime: null,
        endTime: null,
      },
      dataset: {
        paginationConfig: {
          need: true,
        },
        filterLine: {
          show: true,
        },
        selection: {
          need: false,
        },
        header: [
          { prop: "creationDate", label: "评价时间", width: "400" },
          { prop: "gradeType", label: "服务项目", width: "" },
          { prop: "gradeNum", label: "整体满意度", width: "" },
          { prop: "description", label: "评价内容", width: "" },
          { prop: "hasNoPerfect", label: "是否有细项非5星好评", width: "" },
          { prop: "gradeUserName", label: "评价人", width: "" },
          { prop: "gradeUserPhone", label: "手机号码", width: "" },
          { prop: "operation", label: "操作", width: "110" },
        ],
        tableData: [],
        // 搜索行配置
        searchLineConfig: {
          creationDate: {
            type: "dateRange",
            actionType: "goSearch",
            value: [null, null],
          },
          gradeType: {
            type: "selector",
            label: "服务项目",
            value: "",
            placeholder: "请选择服务项目",
            actionType: "goSearch",
            // 1：物业保修；2：停车缴费；4：预约上门；5：投诉建议
            optionList: [
              // {
              //   label: "物业报修",
              //   value: "1",
              // },
              // {
              //   label: "停车缴费",
              //   value: "2",
              // },
              // {
              //   label: "预约上门",
              //   value: "4",
              // },
              // {
              //   label: "投诉建议",
              //   value: "5",
              // },
            ],
          },
          gradeNum: {
            type: "numberInput",
            label: "整体满意度",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入整体满意度",
            prefixIcon: "el-icon-search",
          },
          description: {
            type: "input",
            label: "评价内容",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入评价内容",
            prefixIcon: "el-icon-search",
          },
          hasNoPerfect: {
            type: "selector",
            label: "是否有细项非5星好评",
            actionType: "goSearch",
            value: "",
            placeholder: "请选择是否有细项非5星好评",
            optionList: [
              {
                label: "是",
                value: "是",
              },
              {
                label: "否",
                value: "否",
              },
            ],
          },
          gradeUserName: {
            type: "input",
            label: "评价人",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入评价人",
            prefixIcon: "el-icon-search",
          },
          gradeUserPhone: {
            type: "input",
            label: "手机号码",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入手机号码",
            prefixIcon: "el-icon-search",
          },
          // operation: {
          //   type: "button",
          //   filterCount: "",
          //   actionType: "clickEvent",
          //   eventName: "showMoreFilters",
          //   label: "更多筛选",
          // },
        },
        // 表格内容配置
        detailConfig: {
          hasNoPerfect: {
            type: "enumerationColumn",
            emuList: {
              是: "是",
              否: "否",
            },
          },
          gradeType: {
            type: "enumerationColumn",
            emuList: {
              // 1：物业保修；2：停车缴费；4：预约上门；5：投诉建议
              // 1: "物业报修",
              // 2: "停车缴费",
              // 4: "预约上门",
              // 5: "投诉建议",
            },
          },
          creationDate: {
            type: "dateFormat",
          },
          operation: {
            type: "icon",
            iconList: [
              {
                actionType: "iconClick",
                eventName: "view",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-edit",
                tooltips: "查看",
              },
            ],
          },
        },
        // 高级搜索配置
        advanceFilterConfig: {
          businessStage: {
            inline: true,
            value: "",
          },
          sort: {
            inline: true,
            value: "",
          },
          businessName: {
            inline: true,
            value: "",
          },
          showApplet: {
            inline: true,
            value: "",
          },
        },
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
    };
  },
  watch: {
    listLoading: {
      handler(val) {
        this.$refs.finalTableRef.loadingToggle(val);
      },
    },
  },
  async created() {
    const curSearch = sessionStorage.getItem("evaluation_list_query_params");
    if (curSearch) {
      const tempObj = JSON.parse(curSearch);
      this.requestParams = { ...this.requestParams, ...tempObj };
      Object.keys(this.requestParams).forEach((item) => {
        if (this.dataset.searchLineConfig[item]) {
          this.dataset.searchLineConfig[item].value = this.requestParams[item];
        }
      });
      this.dataset.searchLineConfig.creationDate.value = [
        tempObj.startTime,
        tempObj.endTime,
      ];
    }
    await this.getGradeType();
    this.getListData();
  },
  methods: {
    getGradeType() {
      return new Promise((resolve) => {
        findLookupItem("GRADE_TYPE").then((res) => {
          this.dataset.searchLineConfig.gradeType.optionList = res.map(
            (item) => {
              this.dataset.detailConfig.gradeType.emuList[item.itemCode] =
                item.itemName;
              return {
                label: item.itemName,
                value: item.itemCode,
              };
            }
          );
          resolve();
        });
      });
    },
    getListData() {
      this.listLoading = true;
      getEvalutionList(this.requestParams)
        .then((res) => {
          console.log("res", res);
          this.dataset.tableData = res.rows;
          this.dataset.pageVO.total = res.total;
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    exportHandler() {
      evalutionListExport(this.requestParams)
        .then((taskId) => {
          this.$message({
            type: "success",
            message: this.$t("message.operationSuccess"),
          });
          let exportObj = {
            taskId,
            taskName: "用户评价",
            taskStatus: 0,
            rootPath: "gradePath",
          };
          this.$store.dispatch("PushExportNotice", exportObj);
        })
        .catch(() => {
          this.$message.warning("导出失败！");
        });
    },
    tableEventHandler(datas) {
      if (datas.type === "goSearch") {
        if (datas.params.creationDate && datas.params.creationDate.length > 0) {
          this.requestParams.startTime = datas.params.creationDate[0]
            ? new Date(datas.params.creationDate[0]).getTime()
            : null;
          this.requestParams.endTime = datas.params.creationDate[1]
            ? new Date(datas.params.creationDate[1]).getTime()
            : null;
          delete datas.params.creationDate;
        }
        this.requestParams = {
          ...this.requestParams,
          ...datas.params,
        };
        this.requestParams.current = 1;
        this.dataset.pageVO.current = 1;
        this.getListData();
      } else if (datas.type === "paginationChange") {
        this.requestParams.current = datas.params.current.page;
        this.requestParams.rowCount = datas.params.current.limit;
        this.getListData();
      } else if (datas.type === "iconClick") {
        switch (datas.eventName) {
          case "view":
            this.viewHandler(datas.row);
            break;
        }
      }
    },
    viewHandler(datas) {
      this.viewDatas = datas;
      this.dialogVisible = true;
    },
    handleClose() {
      this.viewDatas = {};
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.user-evaluation-component {
  .filter-line {
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      height: 36px;
    }
    .icon-loudoutu {
      font-size: 12px;
    }
    .header-filter-button {
      cursor: pointer;
      width: 84px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-radius: 3px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
    }
    .filter-button-open {
      color: #409eff;
      background: #ecf5ff;
      border: 1px solid #c6e2ff;
    }
    .filter-button-close {
      color: #2a4158;
      background: #ffffff;
      border: 1px solid #e7e8eb;
    }
  }
}
</style>